import Modal from "./Modal";
import { CommonContext } from "../../../App";
import { useContext } from "react";
import Button from "./Button";
import { GameContext } from "./Core";
import ms from './Game.module.css';
// import { useNavigate } from "react-router-dom";

const Pause = (params) => {
    const { gridRefresh } = params;
    const { timeTotal, timeLevels, setIsPaused, statWindowToggle } = useContext(CommonContext);
    const { level, hardMode, setHardMode, restartGame } = useContext(GameContext);

    // const navigate = useNavigate();

    return (
        <Modal
            caption={`Игра на паузе`}
        >
            <p>Вы находитесь на уровне: <span>{level}</span></p><br />
            <p>Игра начата : <span>{timeTotal} сек.</span> назад (без учета пауз)</p><br />
            <p>Затрачено на уровни:</p>
            <p>1. Карты: {timeLevels[0]} сек.</p>
            <p>2. Коты: {timeLevels[1]} сек.</p>
            <p>3. Коты + карты: {timeLevels[2]} сек.</p>
            <div className={ms.modal__buttons}>
                <Button 
                    action={gridRefresh}
                    title="Перемешать плитки"
                />
                <Button
                    action={() => { setHardMode(hardMode => {
                        // setHardMode(!hardMode)
                        // navigate('/')
                        restartGame();
                        return !hardMode;
                    }) }}
                    title={hardMode? "Сложные уровни: выкл." : "Сложные уровни: вкл."}
                />
            </div>
            <div className={ms.modal__buttons}>
            <Button
                action={() => { setIsPaused(false); statWindowToggle(false) }}
                title="Продолжить"
            />
            </div>
        </Modal>
    );
};

export default Pause;