const Spinner = (s) => {
    return (
        <div className="spinner">
            <style>{`
        .spinner {
          border: 4px solid #f3f3f3;
          border-top: 4px solid ${s.color};
          border-radius: 50%;
          width: ${s.size}px;
          height: ${s.size}px;
          animation: spin 2s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
        </div>
    );
};

export default Spinner;