import ms from './Form.module.css';
// import { Link } from "react-router-dom";
// import Button from "../game/GameCore/Button";

const Form = () => {
    return (
        <div className={ms.form}>
            <div>
                <script src="https://yastatic.net/s3/frontend/forms/_/embed.js"></script>
                <iframe 
                    src="https://forms.yandex.ru/u/6507fd013e9d08ef1fc12811/?iframe=1" 
                    name="ya-form-6507fd013e9d08ef1fc12811"
                    width="600"
                    height="560"
                    frameBorder="0"
                    title='FORM'
                />
            </div>
            {/* <Link to="/">
                <Button title="Вернуться на главную" />
            </Link> */}
            {/* <Link to="/"> */}
        </div>
    );
}
export default Form;