import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { CommonContext } from '../App';
import Button from '../pages/game/GameCore/Button';

// import logo from './logo192.png';

const Header = () => {
    
    const { setIsPaused, statWindowToggle, isPaused, statWindow, gameStarted, developerMode, hardMode } = useContext(CommonContext);

    const openModal = () => {
        if (isPaused && !statWindow)
            return;
        setIsPaused(paused => !paused);
        statWindowToggle(toggled => !toggled);
    };

    const currentLocation = useLocation().pathname;

    return (
        <header>
            <h1>Потренируем внимательность?</h1>
            {developerMode >= 0 &&
            <>
                <p>Stat: {statWindow ? "true" : "false"}</p>
                <p>Pause: {isPaused ? "true" : "false"}</p>
                <p>Start: {gameStarted ? "true" : "false"}</p>
                <p>Developer: {developerMode}</p>
            </>
            }
            {hardMode &&
                <p><span className='bold'>Активен режим:</span> сложные уровни</p>
            }
            <div className='header_buttons'>
                <Link to="/">
                    <Button title="Главная" />
                </Link>
                {(currentLocation === '/game' && gameStarted) && 
                    <Button title="Пауза" action={openModal}/>
                }
            </div>
        </header>
    );
}

export default Header;