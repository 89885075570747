import React, { useContext, useEffect } from "react";
import ms from './Game.module.css';
import { GameContext } from './Core';

import Tile from './Tile';

import Spinner from "../../../GCMP/Spinner";
import { CommonContext } from "../../../App";

const GameField = () => {
    const { grid, isPaused, level, hardMode, refreshGrid } = useContext(GameContext);
    const { gameStarted } = useContext(CommonContext)
    useEffect(() => {
        if (!isPaused && gameStarted) {
            if (grid.length === 0) {
                refreshGrid();
            }
        }
    }, [
        isPaused, gameStarted, grid.length, refreshGrid,
    ]);


    return (
        <div className={`${ms.gamefield} ${ms.dragging} ${isPaused ? ms.modal__activate : ''} ${hardMode ? ms['gfl' + level] : ms.gfl1}`}>
            {
                grid.length === 0 ? 
                    <Spinner size="30" color="#fadf12" />
                :
                grid.map((_, index) => (
                    <Tile key={index} index={index} />
                ))
            }
        </div>
    );
}

export default GameField;