import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <p>Не используйте на этой платформе данные от рабочего аккаунта</p>
            <Link to="https://t.me/+ejJYX8LjdGljN2Qy" target="_blank">
                <span className="bold">Мы в Telegram</span>
            </Link> | <Link to="/form" target="_blank">
                <span className="bold">Обратная связь</span>
            </Link>
        </footer>
    );
};

export default Footer;