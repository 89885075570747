import React, { useState, useEffect, createContext, useContext } from "react";
import { CommonContext } from "../../../App";
import ms from './Game.module.css';

import GameField from "./GameFiled";
import Modal from "./Modal";
import Button from "./Button";
import PauseWindow from "./PauseWindow";

var imagesArray = [
    Array.from({ length: 21 }, (_, i) => require(`./img/tiles/LVL1/${i + 1}.png`)),
    Array.from({ length: 19 }, (_, i) => require(`./img/tiles/LVL2/${i + 1}.png`)),
];

imagesArray[2] = [...imagesArray[0],...imagesArray[1]];

export const GameContext = createContext();

const Field = () => {
    const startSpeed = 800;
    const speedDecrease = startSpeed / 4.5;

    const [grid, setGrid] = useState([]);
    const [level, setLevel] = useState(1);
    const [speed, setLevelSpeed] = useState(startSpeed);

    const [firstFlip, setFirstFlip] = useState(null);

    const [pairIndex, setPairIndex] = useState(null);

    const { hardMode, setHardMode } = useContext(CommonContext);
    
    const { 
        timeLevels, setTimeLevels,
        timeTotal, setTimeTotal,
        isPaused, setIsPaused,
        statWindow, statWindowToggle,
        gameStarted, setGameStarted,
        developerMode
    } = useContext(CommonContext);

    const refreshGrid = () => {
        startGame(true);
    }

    useEffect(() => {
        // if (gameStarted && !isPaused && !statWindow && (grid.every(tile => tile.matched))) {
        if (!isPaused && gameStarted) {
            const interval = setInterval(() => {
                setTimeTotal(timeTotal => timeTotal + 1);
                setTimeLevels(timeLevels => {
                    const newTimes = [...timeLevels];
                    newTimes[level -1] = newTimes[level - 1] + 1;
                    return newTimes;
                })
            }, 1000);
            return () => clearInterval(interval);
        }

    }, [
        isPaused, gameStarted, setTimeTotal, setTimeLevels, level
    ]);

    const nextLevel = () => {
        if (level < 3) {
            setLevel(level + 1);
            setLevelSpeed(speed - speedDecrease);
            setIsPaused(false);
            startGame();
        }
    }

    const handleClick = (index) => {
        if (grid[index].matched || grid[index].flipped || grid.filter(tile => tile.flipped && !tile.matched).length === 2 || isPaused) return;

        const newGrid = [...grid];
        newGrid[index].flipped = true;
        setGrid(newGrid);

        if (firstFlip === null) {
            setFirstFlip(index);
        } else {
            setPairIndex(null);
            if (newGrid[firstFlip].image === newGrid[index].image) {
                newGrid[firstFlip].matched = true;
                newGrid[index].matched = true;
                setFirstFlip(null);
            } else {
                setTimeout(() => {
                    newGrid[firstFlip].flipped = false;
                    newGrid[index].flipped = false;
                    setGrid(newGrid);
                    setFirstFlip(null);
                }, speed);
            }
        }
        
        if (developerMode >= 0) {
            let pairIndex = -1;
            for (let i = 0; i < grid.length; i++) {
                if (grid[i].image === newGrid[index].image && i !== index) {
                    pairIndex = i;
                }
            }
            if (developerMode === 0) {
                setPairIndex(pairIndex);
            } else if (developerMode === 1) {
                newGrid[pairIndex].matched = true;
                newGrid[pairIndex].flipped = true;
                newGrid[index].matched = true;
                newGrid[index].flipped = true;
                setFirstFlip(null);
                setGrid(newGrid);
            }
        }

        if (newGrid.every(tile => tile.matched)) {
            setIsPaused(true);
        }

    }

    // Fisher-Yets
    const shuffle = (array) => {
        let currentIndex = array.length, temproaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temproaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temproaryValue;
        }
        return array;
    }

    const restartGame = () => {
        for (let i = 0; i < grid.length; i++) {
            grid[i] = {}
        }
        setLevelSpeed(startSpeed);
        setTimeLevels([0, 0, 0]);
        setTimeTotal(0);
        setIsPaused(false);
        setLevel(1);
        setGameStarted(false);
        startGame(false);
    }

    const startGame = (reset = false) => {
        setIsPaused(false);
        statWindowToggle(false);
        if (level === 3 && reset !== true) {
            setLevelSpeed(startSpeed);
            setTimeLevels([0, 0, 0]);
            setTimeTotal(0);
            setIsPaused(false);
            setLevel(1);
            setGameStarted(false);
        } else if (level === 1) {
            setGameStarted(true);
        }

        setLevel((currentLevel) => {
            const imagesList = imagesArray[currentLevel - 1];
            const shuffledImages = imagesList.sort(() => Math.random() - 0.5);

            let count = 8;
            if (hardMode) {
                if (currentLevel === 2)
                    count = 18;
                if (currentLevel === 3)
                    count = 32;
            }

            const selectedImages = shuffledImages.slice(0, count);

            const imagePairs = shuffle([...selectedImages, ...selectedImages].sort(() => Math.random() - 0.5));

            const newGrid = imagePairs.map((image) => ({
                image,
                flipped: false,
                matched: false,
            }));
            setGrid(newGrid);
            return currentLevel;
        });

        if (reset === true) {
            grid.forEach(tile => {
                tile.flipped = false;
                tile.matched = false;
                setFirstFlip(null);
                setPairIndex(null);
            });
        }

    };

    const contextValue = {
        grid,
        handleClick,
        pairIndex,
        level,
        speed,
        isPaused,
        timeLevels,
        timeTotal,
        refreshGrid,
        hardMode,
        setHardMode,
        startGame,
        restartGame
    };

    return (
        <GameContext.Provider value={contextValue}>
            {!gameStarted && level === 1 ? (
                <Button action={startGame} title="Начать игру" className={ms.game_start} />
            ) : (
                <>
                    <GameField />
                    {(isPaused && !statWindow) &&
                    <Modal 
                        caption={`Раунд ${level} пройден!`}
                    >
                        <p>Времени понадобилось: {timeLevels[level - 1]} сек.</p>
                        <p>Скорость была: {(speed / 1000).toFixed(2)}</p>
                        {(level === 3) ? <>
                            <br/>
                            <p>Раунды пройдены за {timeTotal} сек.</p>
                            <p>Паузы не учитываются</p>
                            <div className={ms.modal__buttons}>
                                <Button 
                                    action={startGame} 
                                    title={"Начать заново?"}
                                />
                            </div>
                        </> : <>
                            <br />
                            <Button 
                                action={nextLevel} 
                                title="Следующий раунд"
                            />
                        </>
                        }
                    </Modal>}
                    {(isPaused && statWindow) &&
                        <PauseWindow gridRefresh={refreshGrid} />
                    }
                </>
            )}
        </GameContext.Provider>
    );
};

export default Field;