import React from "react";
import ms from './Game.module.css';

const Modal = ({ caption, children }) => {
    return (
        <div className={ms.modal}>
            <div className={ms.modal__caption}>
                {caption}
            </div>
            {children}
        </div>
    );
};

export default Modal;