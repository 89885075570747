import React, { createContext, useState } from'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './GCMP/style.css';

import Header from './GCMP/Header';
import Footer from './GCMP/Footer';

import Main from './pages/Main/Main';
import GameField from './pages/game/GameCore/Core';
import Form from './pages/Form/Form';

export const CommonContext = createContext();

let developer = -1;
// developer = 0;
// developer = 1;

function App() {

  const [timeLevels, setTimeLevels] = useState([0, 0, 0]);
  const [timeTotal, setTimeTotal] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [statWindow, statWindowToggle] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [developerMode, setDeveloperMode] = useState(Number(developer));
  const [hardMode, setHardMode] = useState(false);
  
  const currentContext = {
    timeLevels, setTimeLevels,
    timeTotal, setTimeTotal,
    isPaused, setIsPaused,
    statWindow, statWindowToggle,
    gameStarted, setGameStarted,
    developerMode, setDeveloperMode,
    hardMode, setHardMode,
  }

  return (
    <CommonContext.Provider value={currentContext}>
      <Router>
        <Header />
          <div className="wrapper">
            <Routes>
              <Route path="/*" element={<Main />} />
              <Route path="/game" element={<GameField />} />
              <Route path="/form" element={<Form />} />
            </Routes>
        </div>
        <Footer />
      </Router>
    </CommonContext.Provider>
  );
}

export default App;
