import React from "react";

const Button = ({ title, className = null, action = null }) => (
    <button
        // className={`${ms.button} ${className !== null ? className : ""}`}
        className={className}
        onClick={action}
    >
        {title}
    </button>
);

export default Button;