import React from "react";

import Button from "../game/GameCore/Button";
import { Link } from "react-router-dom";

import fieldJPG from "./img/GameField.jpg";
import catPNG from "./img/17.png";
import wrongPNG from "./img/wrong.png";
import broken from './img/30.png';

import ms from './Main.module.css';

const Main = () => {
    return (
        <>
        {/* <div className={ms.main}> */}
            <h1>Привет, коллега!</h1>
            <br/>
            <div className={ms.cols}>
                <div className={ms.col1}>
                    <img src={fieldJPG} alt="field" />
                </div>
                <div className={ms.col2}>
                    <p>Эта платформа - внутренняя мини-игра, посвященная тренировке внимательности</p>
                    <p>Она сделана в стиле "Найди пару": нужно нажимать на плитку и искать такую же среди остальных</p>
                    <p>Здесь нет сохранений или подсчета очков</p>
                    <p>Поэтому можно спокойно повторять игру раз за разом</p>
                </div>
            </div>
            <div className={ms.cols}>
                <div className={ms.col1}>
                    <img src={catPNG} alt="cat" />
                </div>
                <div className={ms.col2}>
                    <p>Всего готово три раунда:</p>
                    <p>Карты Тинькофф, Домашние животные, Объединение двух предыдущих</p>
                    <p>На втором или на третьем могут быть плитки с подписью Ти ❤ - это фотографии, которые делали ваши коллеги</p>
                </div>
            </div>

            <div className={ms.cols}>
                <div className={ms.col1}>
                    <img src={wrongPNG} alt="cat" />
                </div>
                <div className={ms.col2}>
                    <p>Игра не оптимизирована под маленькое разрешение. Прохождение доступно только с компьютера/ноутбука</p>
                    <p>Это - эксперимент. И бывают случаи, когда что-то может пойти не так</p>
                    {/* <p>Если так случится, оставь ОС <span className="bold">в форме</span> или напиши на почту: <span className="bold">me@skasatkin.ru</span></p> */}
                    <p>Если так случится, оставь ОС <span className="bold"><Link to="/form">в форме</Link></span> или напиши на почту: <span className="bold"><Link to="mailto:me@skasatkin.ru">me@skasatkin.ru</Link></span></p>
                </div>
            </div>
            <div className={ms.cols}>
                <div className={ms.col1}>
                    <img src={broken} alt="cat" />
                </div>
                <div className={ms.col2}>
                    <p>Хочешь увеличить сложность в несколько раз?</p>
                    <p>В любом месте игры нажми на "Пауза" - включи режим "Сложные уровни"</p>
                    <p>Выключается он так же</p>
                </div>
            </div>

            {/* <h1>Начнем?</h1> */}
            <br />
            <Link to="/game">
                <Button 
                    title="Вперед в игру!"
                    className={ms.btn_huge}
                />
            </Link>
            {/* </div> */}
        </>
    );
};

export default Main;