import React, { useContext } from "react";
import ms from './Game.module.css';
import { GameContext } from './Core';

const Tile = ({ index }) => {
    const { grid, handleClick, pairIndex, speed, isPaused } = useContext(GameContext);
    const tile = grid[index];
    // console.log(pairIndex, index);
    return (
        <div
            className={`${ms.tile} ${ms.dragging} ${index === pairIndex ? ms.pair : ''} ${isPaused && ms.tile__paused}`}
            key={index}
            onClick={() => handleClick(index)}
        >
            <div className={ms.img__container}>
                <img
                    src={tile.flipped ? tile.image : require('./img/inv3.png')}
                    alt="tile"
                    style={{
                        opacity: tile.flipped ? 1 : .3,
                        transition: "opacity " + speed / 3 + "ms",
                    }}
                    draggable="false"
                />
            </div>
        </div>
    );

}

export default Tile;